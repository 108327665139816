.MarketingPage {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.screenText {
  z-index: 1000;
  background-color: black;
  color: white;
  padding: 20px 0px;
  text-align: center;
  width: 100vw;
}
