@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  --sb-size: 10px;
  scrollbar-color: "red" "blue";
}

*::-webkit-scrollbar {
  width: var(--sb-size);
}

*::-webkit-scrollbar-track {
  background: var(--colorNeutralBackgroundPressed);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background: var(--colorCompoundBrandStroke);
  border-radius: 10px;
}
